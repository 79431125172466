import React from 'react';

import Layout from '../../layout/AdminLayout';
import ProjectListView from '../../views/admin/companyProject/CompanyProjectListView';
const CompanyProjectList = (): React.ReactElement => {
  return (
    <Layout>
      <ProjectListView></ProjectListView>
    </Layout>
  );
};

export default CompanyProjectList;
