/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  createStyles,
  FormControlLabel,
  StyleRules,
  TextField,
  WithStyles,
  withStyles,
} from '@material-ui/core';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Autocomplete from '@material-ui/lab/Autocomplete';

// import { AppState } from '../../reducers';
import LANGUAGE_WORLD from '../../utils/languageType';

const styles = (): StyleRules =>
  createStyles({
    content: {
      padding: '1rem',
      width: '30rem',
    },

    selectWidth: {
      maxWidth: '20rem',
      margin: '1rem 0',
    },

    languageLabel: {
      maxWidth: '20rem',
      border: '1px dashed #e4e4ee',
      lineHeight: '2rem',
      margin: '1rem 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    textRight: {
      textAlign: 'right',
    },
    box: {
      margin: '1rem 0',
      padding: '1rem',
    },
  });

const ConsultantFilterDom = ({ classes, onChangefilter }: Props): ReactElement => {
  // const dispatch = useDispatch();

  const [addressState, setAddressState] = useState([]);
  const [industryArr, setIndustryArr] = useState([]);
  const [specialArr, setSpecialArr] = useState([]);

  const falseEducation = {
    MiddleSchool: false,
    VocationalSchool: false,
    Diploma: false,
    Bachelor: false,
    Master: false,
    Doctoral: false,
  };
  const trueEducation = {
    MiddleSchool: true,
    VocationalSchool: true,
    Diploma: true,
    Bachelor: true,
    Master: true,
    Doctoral: true,
  };

  const falseWorkMode = {
    remote: false,
    onsite: false,
  };

  const trueWorkYear = {
    work1to3: true,
    work3to5: true,
    work5to8: true,
    work8to15: true,
    workAbove15: true,
  };
  const falseWorkYear = {
    work1to3: false,
    work3to5: false,
    work5to8: false,
    work8to15: false,
    workAbove15: false,
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name == 'SelectAllWorkYear') {
      setSelectAllWorkYear(event.target.checked);
      if (event.target.checked) {
        setWorkingYear(trueWorkYear);
      } else setWorkingYear(falseWorkYear);
    }
    if (event.target.name == 'selectAllEducational') {
      setSelectAllEducational(event.target.checked);
      if (event.target.checked) {
        setEducationState(trueEducation);
      } else setEducationState(falseEducation);
    }
  };

  // 工作经验
  const [selectAllWorkYear, setSelectAllWorkYear] = useState(false);
  const [workingYear, setWorkingYear] = useState(falseWorkYear);

  const workYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWorkingYear({ ...workingYear, [event.target.name]: event.target.checked });
  };

  // 学历
  const [selectAllEducational, setSelectAllEducational] = useState(false);
  const [educationState, setEducationState] = useState(falseEducation);

  const educationChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEducationState({ ...educationState, [event.target.name]: event.target.checked });
  };

  // 工作方式
  const [workScenario, setWorkScenario] = useState(falseWorkMode);

  const workModeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWorkScenario({ ...workScenario, [event.target.name]: event.target.checked });
  };

  // 语言
  interface selectValue {
    value: string;
    label: string;
  }

  const [languageState, setLanguageState] = useState<selectValue[]>([]);

  const deleteLanguageStateItem = (e: selectValue) => {
    const newArray = languageState.filter(item => {
      return !JSON.stringify(item).includes(JSON.stringify(e));
    });
    setLanguageState(newArray);
  };

  const allButtonReset = () => {
    setAddressState([]);
    setIndustryArr([]);
    setSpecialArr([]);
    setWorkingYear(falseWorkYear);
    setEducationState(falseEducation);
    setWorkScenario(falseWorkMode);
    setLanguageState([]);
  };

  useEffect(() => {
    Object.values(workingYear).some(x => x === false)
      ? setSelectAllWorkYear(false)
      : setSelectAllWorkYear(true);
    Object.values(educationState).some(x => x === false)
      ? setSelectAllEducational(false)
      : setSelectAllEducational(true);

    onChangefilter({
      addressState,
      industryArr,
      specialArr,
      workingYear,
      workScenario,
      educationState,
      languageState,
    });
  }, [
    addressState,
    industryArr,
    specialArr,
    workingYear,
    workScenario,
    educationState,
    languageState,
  ]);

  return (
    <>
      <div className={classes.content}>
        <div className={classes.box}>
          <div>资深度</div>

          <FormControlLabel
            control={
              <Checkbox
                checked={selectAllWorkYear}
                onChange={handleChange}
                name="SelectAllWorkYear"
                color="primary"
              />
            }
            label="全部"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={workingYear.work1to3}
                onChange={workYearChange}
                name="work1to3"
                color="primary"
                value="1-3"
              />
            }
            label="初级助理（1-3年）"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={workingYear.work3to5}
                onChange={workYearChange}
                name="work3to5"
                color="primary"
              />
            }
            label="专员及助理经理（3-5年）"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={workingYear.work5to8}
                onChange={workYearChange}
                name="work5to8"
                color="primary"
              />
            }
            label="顾问及经理（5-8年）"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={workingYear.work8to15}
                onChange={workYearChange}
                name="work8to15"
                color="primary"
              />
            }
            label="资深顾问及资深经理（8-15年）"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={workingYear.workAbove15}
                onChange={workYearChange}
                name="workAbove15"
                color="primary"
              />
            }
            label="执行顾问及总监（15年+）"
          />
        </div>

        <div className={classes.box}>
          <div>学历要求：</div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAllEducational}
                  onChange={handleChange}
                  name="selectAllEducational"
                  color="primary"
                />
              }
              label="全部"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={educationState.MiddleSchool}
                  onChange={educationChange}
                  name="MiddleSchool"
                  color="primary"
                />
              }
              label="不限学历"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={educationState.VocationalSchool}
                  onChange={educationChange}
                  name="VocationalSchool"
                  color="primary"
                />
              }
              label="高中/中专"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={educationState.Diploma}
                  onChange={educationChange}
                  name="Diploma"
                  color="primary"
                />
              }
              label="大专"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={educationState.Bachelor}
                  onChange={educationChange}
                  name="Bachelor"
                  color="primary"
                />
              }
              label="本科"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={educationState.Master}
                  onChange={educationChange}
                  name="Master"
                  color="primary"
                />
              }
              label="硕士"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={educationState.Doctoral}
                  onChange={educationChange}
                  name="Doctoral"
                  color="primary"
                />
              }
              label="博士"
            />
            {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={educationState.xl7}
                    onChange={educationChange}
                    name="xl7"
                    color="primary"
                  />
                }
                label="无学历要求"
              /> */}
          </div>
        </div>
        <div className={classes.box}>
          <div>语言：</div>
          <Autocomplete
            size="small"
            className={classes.selectWidth}
            // defaultValue={null}
            id="combo-box-demo"
            options={LANGUAGE_WORLD}
            getOptionLabel={option => option.label}
            renderInput={params => {
              return <TextField {...params} label="选择语言" variant="outlined" />;
            }}
            // value={
            //   (i18n.language == 'enUS' ? CertificateTypeArrayEn : CertificateTypeArray).find(
            //     option => option.value === field.value
            //   ) || null
            // }
            onChange={(_, newValue) => {
              if (newValue) {
                setLanguageState([...new Set([newValue, ...languageState])]);
              }
            }}
          />
          {languageState.map((e, index) => {
            return (
              <div key={index} className={classes.languageLabel}>
                {e.label} <DeleteForeverIcon onClick={() => deleteLanguageStateItem(e)} />
              </div>
            );
          })}
        </div>
        <div className={classes.box}>
          <div>工作方式：</div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={workScenario.remote}
                  onChange={workModeChange}
                  name="remote"
                  color="primary"
                />
              }
              label="线上"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={workScenario.onsite}
                  onChange={workModeChange}
                  name="onsite"
                  color="primary"
                />
              }
              label="线下"
            />
          </div>
        </div>

        <div className={classes.textRight}>
          <Button size="small" variant="contained" onClick={allButtonReset}>
            全部重置
          </Button>
        </div>
      </div>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  onChangefilter: Function;
}

export default withStyles(styles)(ConsultantFilterDom);
