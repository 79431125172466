import { gql } from '@apollo/client';
import { saveAs } from 'file-saver';

import { loadingEndAction, loadingStartAction } from '../../reducers/loadingReducer';
import { hideNotificationAction, showNotificationAction } from '../../reducers/notificationReducer';
import { mutate, query } from '../../utils/graphql';

const ADMIN_GET_CONSULTANT_DETAIL_TO_PRINT = gql`
  query QUERY($consultantId: Long!, $token: String!) {
    adminGetConsultantDetailToPrint(consultantId: $consultantId, token: $token)
  }
`;
const ADMIN_DELETE_CONSULTANT_LIST = gql`
  mutation MUTATION($consultantIdList: [Long]!) {
    adminDeleteConsultantList(consultantIdList: $consultantIdList)
  }
`;
const ADMIN_DELETE_PROJECT_REQUEST = gql`
  mutation MUTATION($projectRequestId: Long!) {
    adminDeleteProjectRequest(projectRequestId: $projectRequestId)
  }
`;
export const printConsultantDetail = (consultantId: number): Function => async (
  dispatch: Function,
  getState: Function
): Promise<void> => {
  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  const token = getState().user.token;
  const { data, error } = await query(
    ADMIN_GET_CONSULTANT_DETAIL_TO_PRINT,
    { consultantId, token },
    { fetchPolicy: 'no-cache' }
  );
  dispatch(loadingEndAction());

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: `下载失败${error.message}` }));
    return;
  }

  if (data?.adminGetConsultantDetailToPrint) {
    const pdf = data?.adminGetConsultantDetailToPrint;
    const blob = await (await fetch(`data:pdf;base64,${pdf}`)).blob();
    saveAs(blob, `consultant_cv_${consultantId}.pdf`);
    dispatch(loadingEndAction());
    return;
  }
};

export const printConsultantDetailList = (consultantIds: number[]): Function => async (
  dispatch: Function
): Promise<void> => {
  for (let i = 0; i < consultantIds.length; i++) {
    dispatch(printConsultantDetail(consultantIds[i]));
  }
};

export const adminDeleteProjectRequest = (projectRequestId: number): Function => async (
  dispatch: Function
): Promise<boolean> => {
  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  const { data, error } = await mutate(ADMIN_DELETE_PROJECT_REQUEST, { projectRequestId });
  dispatch(loadingEndAction());

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: `项目删除失败` }));
    return false;
  }

  if (data?.adminDeleteProjectRequest) {
    dispatch(showNotificationAction({ severity: 'success', message: '项目删除成功' }));
    dispatch(loadingEndAction());
    return true;
  }
  return false;
};

export const adminDeleteConsultantList = (consultantIdList: number[]): Function => async (
  dispatch: Function
): Promise<void> => {
  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  const { data, error } = await mutate(
    ADMIN_DELETE_CONSULTANT_LIST,
    { consultantIdList },
    {
      fetchPolicy: 'no-cache',
    }
  );
  dispatch(loadingEndAction());

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: `删除失败` }));
    return;
  }

  if (data?.adminDeleteConsultantList) {
    dispatch(showNotificationAction({ severity: 'success', message: '删除成功' }));
    dispatch(loadingEndAction());
    return;
  }
};
